import cn from 'classnames';
import { ReactNode } from 'react';

import { Breakpoint } from '@hooks/useBreakpoints';

interface ArticleBadgeProps {
  className?: string;
  full?: Breakpoint | 'always';
  renderIcon?: (className: string) => ReactNode;
  children?: ReactNode;
}

export default function ArticleBadge({
  className,
  full,
  renderIcon,
  children
}: ArticleBadgeProps) {

  const getWrapperClassName = () => {
    switch (full) {
      case 'always':
        return '';
      case 'sm':
        return 'p-1 sm:mr-1 sm:px-1 sm:py-0.5 ';
      case 'md':
        return 'p-1 md:mr-1 md:px-1 md:py-0.5';
      case 'lg':
        return 'p-1 lg:mr-1 lg:px-1 lg:py-0.5';
      case 'xl':
        return 'p-1 xl:mr-1 xl:px-1 xl:py-0.5';
      case 'xxl':
        return 'p-1 xxl:mr-1 xxl:px-1 xxl:py-0.5';
      default:
        return '';
    }
  };

  const getIconClassName = () => {
    switch (full) {
      case 'always':
        return 'm-1';
      case 'sm':
        return 'sm:m-1';
      case 'md':
        return 'md:m-1';
      case 'lg':
        return 'lg:m-1';
      case 'xl':
        return 'xl:m-1';
      case 'xxl':
        return 'xxl:m-1';
      default:
        return '';
    }
  };

  return (
    <div
      className={cn(
        'flex items-center border-solid border border-orange rounded-full',
        getWrapperClassName(),
        className,
      )}
    >
      {renderIcon &&
        renderIcon(cn('h-3 w-3', getIconClassName()))}
      {children && (
        <span className='text-white text-xxl font-semibold leading-3 text-center px-2'>
          {children}
        </span>
      )}
    </div>
  );
}
