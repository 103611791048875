export const isFeatureValueActive = (cookieValue: any, featureValue: any, expectedValue?: any) => {
  if (cookieValue === undefined) {
    cookieValue = featureValue;
  }
  if (cookieValue === 'false') {
    cookieValue = false;
  }
  if (cookieValue === 'true') {
    cookieValue = true;
  }

  return typeof expectedValue !== 'undefined' ? cookieValue == expectedValue : cookieValue;
};
