import get from 'lodash/get';

import { WRPost } from '@utils/api/server';
import { WP_PREMIUM_ID } from 'enums/wordpress';
import { ArticleI } from 'types/article';

export const getArticleUrl = (article: ArticleI) => {
  return `/${get(article, 'slug', get(article, 'postSlug', ''))}`;
};

export const getLiveStatus = (article: any) => get(article, 'title', '').includes('[LIVE]');

export const getCoverPhotoAlt = (article: any, length = 100) => {
  const alt = article?.alt || article?.title;
  return (alt || '').slice(0, length);
};

export const getPremiumStatus = (article: any) => {
  return article?.categories
    ? !!article?.categories?.find(
      (category) => category.id === WP_PREMIUM_ID[process.env.NEXT_PUBLIC_ENV ?? 'production'][article?.langName],
    )
    : false;
};

export type ArticleBadge = 'live' | 'premium';
export const getArticleBadges = (article: ArticleI | WRPost) => {
  const badges: ArticleBadge[] = [];
  if (getLiveStatus(article)) {
    badges.push('live');
  }
  if (getPremiumStatus(article)) {
    badges.push('premium');
  }
  return badges;
};
