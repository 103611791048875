import replace from 'lodash/replace';

import { matchTrackingDomain } from '@lib/sources';
import { WRPost } from '@utils/api/server';
import { snakeToCamelCaseObject } from '@utils/api/server/wr/common';
import { ArticleI } from 'types/article';
import { ImageSize } from 'types/post';

export const getCloudflareImageLink = (sourceUrl: string) => {
  if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ENV === 'production' && sourceUrl) {
    sourceUrl = replace(
      sourceUrl,
      new RegExp('https://d3w37elt2dcq1p.cloudfront.net/', 'g'),
      'https://cdn-trans.info/',
    );
    sourceUrl = replace(
      sourceUrl,
      new RegExp('https://d2knps3odo7xrl.cloudfront.net/', 'g'),
      'https://cdn-trans.info/',
    );
    return replace(sourceUrl, new RegExp('https://d1dcnte8mfzkpv.cloudfront.net/', 'g'), 'https://cdn-trans.info/');
  }
  return sourceUrl;
};

const getArticleImgUrlCanonical = (article: ArticleI | WRPost | null | undefined, size: ImageSize = 'medium') => {
  if (!article) {
    return 'https://cdn-trans.info/uploads/2017/09/86d06cfc167f8e3f01657fde7eb-1024x684.jpg';
  }

  const _article = snakeToCamelCaseObject(article);

  const articleThumb = (article, size) => {
    if (size === 'medium' && article.featuredImageMedium) {
      return article.featuredImageMedium;
    }
    if (size === 'large' && article.featuredImageLarge) {
      return article.featuredImageLarge;
    }
    if (size === 'thumb' && article.featuredImageThumb) {
      return article.featuredImageThumb || article.postFeaturedImageThumb;
    }
    return article.featuredImage?.sourceUrl;
  };

  if (_article.type === 'videos' || _article.postType === 'videos') {
    const wpVideoThumb = getCloudflareImageLink(articleThumb(article, size));
    return wpVideoThumb || `https://img.youtube.com/vi/${_article.video}/maxresdefault.jpg`;
  }

  return getCloudflareImageLink(
    articleThumb(article, size) ||
      _article.featuredImage ||
      _article.image ||
      'https://cdn-trans.info/uploads/2017/09/86d06cfc167f8e3f01657fde7eb-1024x684.jpg',
  );
};

export const getArticleImgUrl = (article: ArticleI | WRPost | null | undefined, size: ImageSize = 'medium'): string => {
  const url = getArticleImgUrlCanonical(article, size);
  // check domains
  if (matchTrackingDomain(url)) {
    return `/apis/img?url=${encodeURIComponent(url)}`;
  }
  return url;
};
