export const WP_IAA_ID = {
  development: {
    pl: 'cG9zdDo5MDQx',
    de: 'cG9zdDo5MDE4',
    en: 'cG9zdDo5MDQx',
    lt: 'cG9zdDo5MDQx',
    ru: 'cG9zdDo5MDQx',
    ro: 'cG9zdDo5MDQx',
    hu: 'cG9zdDo5MDQx',
    es: 'cG9zdDo5MDQx',
    ua: 'cG9zdDo5MDQx',
  },
  production: {
    pl: 'cG9zdDozMDUyMjI=',
    de: 'cG9zdDozMDUyMjA=',
    en: 'cG9zdDozMDUyMjI=',
    lt: 'cG9zdDozMDUyMjI=',
    ru: 'cG9zdDozMDUyMjI=',
    ro: 'cG9zdDozMDUyMjI=',
    hu: 'cG9zdDozMDUyMjI=',
    es: 'cG9zdDozMDUyMjI=',
    ua: 'cG9zdDozMDUyMjI=',
  },
  staging: {
    pl: 'cG9zdDo5MTA3NA==',
    de: 'cG9zdDo5MTA3NA==',
    en: 'cG9zdDo5MTA3NA==',
    lt: 'cG9zdDo5MTA3NA==',
    ru: 'cG9zdDo5MTA3NA==',
    ro: 'cG9zdDo5MTA3NA==',
    hu: 'cG9zdDo5MTA3NA==',
    es: 'cG9zdDo5MTA3NA==',
    ua: 'cG9zdDo5MTA3NA==',
  },
};

export const WP_MAGAZINE_ID = {
  development: {
    pl: 'cG9zdDo4OTI3',
    de: 'cG9zdDo4ODY3',
    en: 'cG9zdDo4OTI3',
    lt: 'cG9zdDo5MDU4',
    ru: 'cG9zdDo4OTI3',
    ro: 'cG9zdDo4OTI3',
    hu: 'cG9zdDo4OTI3',
    es: 'cG9zdDo4OTI3',
    ua: 'cG9zdDo4OTI3',
  },
  production: {
    pl: 'cG9zdDoyNzY5Mzg=',
    de: 'cG9zdDoyNzI0NjA=',
    en: 'cG9zdDoyNzY5Mzg=',
    lt: 'cG9zdDozMTIzOTk=',
    ru: 'cG9zdDoyNzY5Mzg=',
    ro: 'cG9zdDoyNzY5Mzg=',
    hu: 'cG9zdDoyNzY5Mzg=',
    es: 'cG9zdDoyNzY5Mzg=',
    ua: 'cG9zdDoyNzY5Mzg=',
  },
  staging: {
    pl: 'cG9zdDo5MTAyMg==',
    de: 'cG9zdDo5MTAyMg==',
    en: 'cG9zdDo5MTAyMg==',
    lt: 'cG9zdDo5MTAyMg==',
    ru: 'cG9zdDo5MTAyMg==',
    ro: 'cG9zdDo5MTAyMg==',
    hu: 'cG9zdDo5MTAyMg==',
    es: 'cG9zdDo5MTAyMg==',
    ua: 'cG9zdDo5MTAyMg==',
  },
};

export const WP_MAGAZINE_NUM_ID = {
  development: {
    pl: 8867,
    de: 8867,
    lt: 9058,
  },
  production: {
    pl: 276938,
    de: 272460,
    lt: 312399,
  },
};
export const WP_GUIDE_ID = {
  development: 8867,
  production: 276938,
};

export const WP_WEBINAR_ID = {
  development: {
    pl: 8907,
  },
  production: {
    pl: 276930,
  },
};

export const WP_PREMIUM_ID = {
  development: {
    pl: 1455,
  },
  staging: {
    pl: 10574,
  },
  production: {
    pl: 62115,
  },
};

export const WP_INTERVIEWS_ID = {
  development: {
    pl: 1200,
  },
  staging: {
    pl: 10310,
  },
  production: {
    pl: 43552,
  },
};

export const WP_PODCASTS_ID = {
  development: {
    pl: 1475,
  },
  staging: {
    pl: 10576,
  },
  production: {
    pl: 64117,
  },
};

export const WP_RULES = {
  development: {
    rules: {
      pl: 'regulaminy-pl',
    },
    tos: {},
  },
  staging: {
    rules: {},
    tos: {},
  },
  production: {
    rules: {
      pl: 'regulaminy-pl',
    },
    tos: {},
  },
};
