import { logger } from '@logger';
export type WRObjectQueryFilterRelation = 'AND' | 'OR';
export type WRObjectQueryFilter<IDType extends string | number = number> = {
  id: IDType | IDType[];
  relation?: WRObjectQueryFilterRelation;
};
export type WRQueryOrder = 'asc' | 'desc';

export const pascalToSnakeCase = (input: string) => {
  return input
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

export const pascalToSnakeCaseObject = (
  target: Record<string, any>,
  recursive = true
) => {
  const newObj: Record<string, any> = {};

  Object.entries(target).forEach(([key, value]) => {
    newObj[pascalToSnakeCase(key)] =
      typeof value === 'object' && !Array.isArray(value) && recursive
        ? pascalToSnakeCaseObject(value)
        : value;
  });

  return newObj;
};

export const snakeToCamelCase = (input: string) => {
  return input
    .split('_')
    .map((word, index) =>
      index > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
    )
    .join('');
};

export const snakeToCamelCaseObject = (
  target: Record<string, any>,
  recursive = true
) => {
  const newObj: Record<string, any> = {};

  Object.entries(target).forEach(([key, value]) => {
    newObj[snakeToCamelCase(key)] =
      typeof value === 'object' && !!value && !Array.isArray(value) && recursive
        ? snakeToCamelCaseObject(value)
        : value;
  });

  return newObj;
};

export const isWRObjectQueryFilter = (
  value: any
): value is WRObjectQueryFilter<any> => {
  return typeof value === 'object' && 'id' in value;
};

export const getWRObjectQueryFilterQueryString = (
  filter: WRObjectQueryFilter<any>,
  includeRelation = false
) => {
  if (Array.isArray(filter.id)) {
    if (!includeRelation) {
      return filter.id.join(',');
    }

    return `${filter.id.join(',')}&cat_relation=${filter.relation || 'OR'}`;
  }

  return filter.id.toString();
};

export const mergeWRObjectQueryFilter = <
  T extends string | number = string | number
>(
  a: WRObjectQueryFilter<T>,
  b: WRObjectQueryFilter<T>
): WRObjectQueryFilter<T> => {
  if (a.relation && b.relation && a.relation !== b.relation) {
    logger.warn(
      '[WRQueryFilter] Relation type mismatch. Query results might be unexpected.'
    );
  }

  const aIds = Array.isArray(a.id) ? a.id : [a.id];
  const bIds = Array.isArray(b.id) ? b.id : [b.id];

  return {
    id: [...aIds, ...bIds],
    relation: a.relation || b.relation || 'OR',
  };
};
