const trackingDomainsPatterns = [
  /tiktok\.com/,
  /youtube\.com/,
  /facebook\.com/,
  /instagram\.com/,
  /twitter\.com/,
  /yt\.com/,
  /ytimg\.com/,
  /trans\.eu/,
  /vimeo\.com/,
  /dailymotion\.com/,
  /soundcloud\.com/,
  /entrypoint\.transinfo/,
];

type Purpose = {
  rule?: RegExp;
  purpose?: string;
  vendor?: string;
};

const defaultPurpose = 'c55'; // "Other"

const purposes: Purpose[] = [
  {
    rule: /tiktok\.com/,
    purpose: 'c56',
    vendor: 's2536',
  },
  {
    rule: /(youtube\.com)|(yt\.com)/,
    purpose: 'c56',
    vendor: 's30',
  },
  {
    rule: /linkedin\.com/,
    purpose: 'c56',
    vendor: 's2',
  },
  {
    rule: /facebook\.com/,
    purpose: 'c56',
    vendor: 's7',
  },
  {
    rule: /instagram\.com/,
    purpose: 'c56',
    vendor: 's14',
  },
  {
    rule: /soundcloud\.com/,
    purpose: 'c56',
    vendor: 's1659',
  },
  {
    rule: /(twitter\.com)|(twimg.com)/,
    purpose: 'c56',
    vendor: 's34',
  },
  {
    rule: /vimeo\.com/,
    purpose: 'c56',
    vendor: 's77',
  },
  {
    rule: /telegram\.com/,
    purpose: 'c56',
    vendor: 'c37356',
  },
  {
    rule: /trans\.eu/,
    purpose: 'c56',
    vendor: 'c34594',
  },
  {
    rule: /maps\.google\.com/,
    purpose: 'c55',
    vendor: 's1104',
  },
  {
    rule: /openstreetmap\.fr/,
    purpose: 'c55',
    vendor: 's4',
  },
];

export const matchLinkToPurpose = (link: string) => {
  const purpose = purposes.find(({ rule }) => rule && rule.test(link));
  return purpose
    ? purpose
    : {
        purpose: defaultPurpose,
      };
};

export const matchTrackingDomain = (link: string) => {
  return trackingDomainsPatterns.some((pattern) => pattern.test(link));
};
