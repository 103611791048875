'use client';
import cn from 'classnames';

import ArticleBadge from './ArticleBadge';

import { Breakpoint } from '@hooks/useBreakpoints';
import Star from 'public/static/premium/star.svg';
import { useFeatureIsActive } from '@components/Only/FeatureIsActive';

interface PremiumBadgeProps {
  className?: string;
  full?: Breakpoint | 'always';
}
export default function PremiumBadge({ className, full }: PremiumBadgeProps) {
  const isPremiumEnabled = useFeatureIsActive('premium.enabled');
  if (!isPremiumEnabled) return <></>;
  return (
    <ArticleBadge
      full={full}
      className={cn('bg-orange text-white', className)}
      renderIcon={(iconClassName) => <Star className={iconClassName} />}
    />
  );
}
